// Translate
// Migrated
<template lang="pug">
button.charter-search__btn(
  :id="id"
  :class="{ active }",
  :aria-expanded="active",
  aria-haspopup="true",
  :aria-labelledby="`${id}Label`",
  @click="$emit('click')"
)
  .charter-search__btn-title.d-flex.w-100(v-if="!slim || loading", :id="`${id}Label`")
    template(v-if="loading")
      b-skeleton.mr-2.d-inline-block(height="16px", width="16px")
      b-skeleton.d-inline-block(height="16px", width="60px")

    template(v-else)
      span.mr-2(v-if="!isCharter && hasIcon")
        slot(name="icon")
      slot(name="label")
      fa.charter-search__btn-icon.ml-auto.transition-transform(
        v-if="isCharter"
        icon="chevron-down"
        :style="{ transform: `rotateX(${active ? '180deg' : '0'})` }"
      )

  .charter-search__btn-text
    template(v-if="loading")
      div(style="width: 65%")
        b-skeleton(width="100%", height="24px")
        b-skeleton(v-if="id === 'charterButtonGuests'", width="80%", height="16px")
      b-skeleton(width="20px", height="24px")

    template(v-else)
      slot(name="content")
      fa.charter-search__btn-icon.d-print-none(
        v-if="!isCharter"
        :icon="indicator"
      )

  template(v-if="!loading")
    slot(name="append")
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    active: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    slim: {
      type: Boolean,
      default: false
    },

    id: {
      type: String,
      required: true
    }
  },

  emits: ['click'],

  computed: {
    ...mapState(useLocaleStore, ['isCharter']),
    indicator () {
      const suffix = this.active ? 'up' : 'down'
      return `chevron-${suffix}`
    },

    hasIcon () {
      if (this.slim) {
        return false
      }
      return slotHasContent('icon', this.$slots)
    }
  }
})
</script>
